import React from 'react';
import QRCode from 'qrcode.react';

import Layout from '../components/Layout';
import MetaData from '../components/meta/MetaData';
import { extLinkClick } from '../utils/general';
import {
	Body,
	Wrapper,
	ReferralHeader,
	ActivateCardWrapper,
	ActivateCardListNmr,
	ActivateCardBody,
	CrowdLink,
	Headline,
	ReferralHeadline,
} from '../styles';

const ActivateCard = () => (
	<Layout>
		<MetaData
			title="Envel - Activate Card"
			description="Instructions to activate you Envel debit card"
			image="images/social/social.png"
		/>

		<ReferralHeader center>Activate Card</ReferralHeader>
		<ReferralHeadline center>To activate your card please follow one of the options below</ReferralHeadline>

		<ActivateCardWrapper row justifyAround alignCenter>
			<QRCode value={'https://envel.ai/activate'} size={200} bgColor="#00000000" fgColor="#fff" />
			<Wrapper width="auto">
				<Wrapper row alignCenter>
					<ActivateCardListNmr style={{ padding: '1px 9.8px' }}>1</ActivateCardListNmr>
					<Body>Please use the camera on your mobile phone to scan the QR code</Body>
				</Wrapper>
				<Wrapper row alignCenter>
					<ActivateCardListNmr>2</ActivateCardListNmr>
					<Body>Follow the link provided</Body>
				</Wrapper>
				<Wrapper row alignCenter>
					<ActivateCardListNmr>3</ActivateCardListNmr>
					<Body>Enter your card&apos;s last 4 digits</Body>
				</Wrapper>
			</Wrapper>
		</ActivateCardWrapper>

		<ActivateCardWrapper>
			<ActivateCardBody center>
				Alternatively, navigate to{' '}
				<CrowdLink onClick={() => extLinkClick('https://www.envel.ai/activate')}>
					https://www.envel.ai/activate
				</CrowdLink>{' '}
				on your mobile phone browser. You will be taken to a screen within the Envel app where you are required to enter
				the last 4 digits of your debit card in order to activate it.
			</ActivateCardBody>
		</ActivateCardWrapper>

		<ActivateCardWrapper row justifyAround alignCenter>
			<Wrapper width="auto">
				<Headline center>
					If the above link doesn&apos;t open the app, you can activate your card in the following these steps
				</Headline>
				<Wrapper row alignCenter>
					<ActivateCardListNmr>1</ActivateCardListNmr>
					<Body>Go to your Wallet screen</Body>
				</Wrapper>
				<Wrapper row alignCenter>
					<ActivateCardListNmr>2</ActivateCardListNmr>
					<Body>Click on &quot;My Debit Card&quot; in the top right</Body>
				</Wrapper>
				<Wrapper row alignCenter>
					<ActivateCardListNmr>3</ActivateCardListNmr>
					<Body>Click on &quot;Activate Card&quot;</Body>
				</Wrapper>
				<Wrapper row alignCenter>
					<ActivateCardListNmr>4</ActivateCardListNmr>
					<Body>Activate your card using the last 4 digits of your card number</Body>
				</Wrapper>
			</Wrapper>
		</ActivateCardWrapper>

		<ActivateCardBody center>
			Please contact us if this is not working for you on{' '}
			<a href="mailto:support@envel.ai" target="_blank" rel="noopener noreferrer">
				support@envel.ai
			</a>{' '}
			or via the chat on the Envel app
		</ActivateCardBody>
	</Layout>
);

export default ActivateCard;
